<template>
	<v-container fluid>
		<v-col md="2" class="mt-2">
			<v-autocomplete
				depressed
				hide-details
				outlined
				placeholder="Select Year"
				item-text="count"
				item-value="year"
				v-model="customFilter"
				:items="userDetail?.anniversaryDates"
				@click:clear="topBarFilter()"
				@change="topBarFilter()"
			/>
			<!-- v-model.trim="serachQuery[filter.key]" -->
		</v-col>
		<v-form
			class="mt-3 mx-2"
			ref="passwordForm"
			v-model.trim="formValid"
			lazy-validation
			@submit.stop.prevent="onSubmit"
		>
			<v-row>
				<v-col md="2" class="pb-2">
					<label class="input-label">Leave Type</label>
				</v-col>
				<v-col md="2" class="pb-2">
					<label class="input-label"> Max Day</label>
				</v-col>
				<v-col md="2" class="pb-2">
					<label class="input-label"> Entitle</label>
				</v-col>
				<v-col md="2" class="pb-2">
					<label class="input-label"> Earn Leave</label>
				</v-col>
				<v-col md="2" class="pb-2">
					<label class="input-label"> Taken</label>
				</v-col>
				<v-col md="2" class="pb-2">
					<label class="input-label"> Balance</label>
				</v-col>
				<!-- <v-col md="2" class="pb-2">
					<label class="input-label"> Interval</label>
				</v-col> -->
			</v-row>

			<v-row v-for="(type, index) in leaveList" :key="index">
				<v-col md="2" class="pb-2">
					<v-text-field
						v-model.trim="type.category"
						outlined
						placeholder="Leave Type"
						hide-details
						readonly
					></v-text-field>
				</v-col>
				<v-col md="2" class="pb-2">
					<v-text-field
						v-model.trim="type.maxdays"
						outlined
						placeholder="Max Day"
						readonly
						hide-details
					></v-text-field>
				</v-col>
				<v-col md="2" class="pb-2">
					<v-text-field
						v-model.trim="type.allocated_days"
						type="number"
						min="0"
						:disabled="currentyear != customFilter"
						v-on:keyup="addminvalue(type)"
						v-on:change="updateEnti(type)"
						max="999"
						outlined
						:readonly="!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin'])"
						placeholder="Allocated Leave"
						hide-details
					></v-text-field>
				</v-col>
				<v-col md="2" class="pb-2">
					<template v-if="type.earn_leave">
						{{ type?.earn_leave }}
					</template>
					<template v-else> 0 </template>
				</v-col>

				<v-col md="2" class="pb-2">
					<template v-if="type?.taken">
						{{ type.taken }}
					</template>
					<template v-else> 0 </template>
				</v-col>

				<v-col md="2" class="pb-2">
					<template v-if="type?.taken">
						{{ type?.allocated_days - type.taken }}
					</template>
					<template v-else-if="type?.allocated_days">
						{{ type?.allocated_days }}
					</template>
					<template v-else> 0 </template>
				</v-col>
				<!-- <v-col md="2" class="pb-2">
					<v-text-field
						outlined
						placeholder="Balance Leave"
						hide-details
						v-model="type.duration_type"
						readonly
				
						v-on:keyup="addminvalue(type)"
						
					></v-text-field>
				</v-col> -->
			</v-row>
			<v-row>
				<v-col class="" md="11">
					<v-btn
						v-if="currentyear == customFilter"
						:disabled="!formValid"
						:loading="formLoading"
						v-on:click="onSubmit"
						class="white--text"
						color="blue darken-4"
					>
						Submit
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>

<script>
// import ValidationMixin from "@/core/plugins/validation-mixin";
import { PUT, GET, QUERY } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
//  import {map} from "lodash";

export default {
	props: {
		user: {
			type: Number,
			default: 0,
			required: true,
		},
		userDetail: {
			type: [String, Array, Object],
			default: () => [],
		},
	},
	watch: {
		user: {
			handler() {
				this.getLeaveDays();
				this.getUser();
			},
		},
	},
	data() {
		return {
			maxlengthValid: false,
			joiningDate: new Date(2023, 0, 1), // January 1, 2023

			leaveList1: [],
			UserList: [],
			currentyear: null,
			customFilter: null,
			formValid: true,
			exitsDays: 0,
			KeyIndays: 0,
			formLoading: false,
			updateDays: 0,
			leaveList: {
				categories: [
					{
						category: null,
						maxdays: 0,
						allocated_days: 0,
						earn_leave: 0,
						taken: 0,
						balance: 0,
					},
				],
			},
		};
	},
	methods: {
		topBarFilter() {
			this.getUser();
		},
		updateEnti(data) {
			let decimals = data.allocated_days - Math.floor(data.allocated_days);
			if (decimals > 0) {
				data.allocated_days = Math.floor(data.allocated_days) + 0.5;
			} else {
				data.allocated_days = Math.floor(data.allocated_days);
			}
			if (data?.duration_type == "yearly") {
				data.earn_leave = Math.floor(data.allocated_days);
			} else {
				data.earn_leave = Math.round(data.allocated_days / 12);
			}
		},
		getLeaveDays() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "setting/leave",
				})
				.then((data) => {
					_this.leaveList1 = data;
					//  console.log(_this.leaveList,'hello');
					// 		 _this.leaveList = map(data, (row)=>{

					// 				return {
					// 					maxdays:row.maxdays,
					// 					category:row.category,
					// 					earn_leave:row?.user_leave?.earn_leave

					// 				}
					//         }
					//    );
				})
				.catch((error) => {
					_this.logError(error);
					_this.goBack();
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},

		addminvalue(data) {
			this.exitsDays = data.maxdays;
			this.KeyIndays = data.allocated_days;

			if (this.exitsDays < this.KeyIndays) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "Entitle more than maxdays." }]);
				/* this.$store.commit(SET_ERROR, 'maxdays less then value'); */
				/* 	ClearEventBus.$emit("update:error", InitializeError(`maxdays less then value `)); */
				this.formValid = false;
				this.maxlengthValid = false;
			} else {
				this.maxlengthValid = true;
				this.formValid = true;
			}
			let decimals = data.allocated_days - Math.floor(data.allocated_days);
			if (decimals > 0) {
				data.allocated_days = Math.floor(data.allocated_days) + 0.5;
			} else {
				data.allocated_days = Math.floor(data.allocated_days);
			}
			if (data?.duration_type == "yearly") {
				data.earn_leave = Math.floor(data.allocated_days);
			} else {
				data.earn_leave = Math.round(data.allocated_days / 12);
			}
		},

		getUser() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(QUERY, {
					url: "single-user/" + _this.user,
					data: {
						year: this.customFilter, // Add key-value pairs as query parameters
					},
				})
				.then((data) => {
					// console.log(data);
					this.UserList = data;
					// this.leaveLists = data.categories;
					const leaveLists = data.categories.map((row) => {
						return {
							category_id: row.id,
							id: row?.user_leave[0]?.id,
							maxdays: row.maxdays,
							category: row.category,
							earn_leave: row?.user_leave[0]?.earn_balance,
							taken: row?.user_leave[0]?.taken,
							allocated_days: row?.user_leave[0]?.allocated_days,
							year: row?.user_leave[0]?.year,
							duration_type: row?.duration_type,
						};
					});

					_this.customFilter = Number(data?.year);

					_this.currentyear = Number(data?.current_year);
					if (!_this.customFilter) {
						_this.customFilter = _this.currentyear;
					}

					_this.leaveList = leaveLists;

					console.log(data.categories, "hello");
				})

				.catch((error) => {
					_this.logError(error);
					console.log(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		onSubmit() {
			const _this = this;
			if (!_this.$refs.passwordForm.validate()) {
				return false;
			}
			if (!_this.maxlengthValid) {
				return false;
			}
			_this.formLoading = true;

			_this.$store
				.dispatch(PUT, {
					url: "leave-update/" + _this.user,
					data: { categories: this.leaveList },
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Leave update successfully.",
						},
					]);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
				});
		},
	},

	mounted() {
		const _this = this;
		_this.getUser();
		_this.getLeaveDays();
	},
};
</script>
