<template>
	<v-layout class="user-listing h-100 flex-wrap">
		<v-flex class="w-100 user-detail-layout">
			<div class="user-listing-card d-flex border-bottom">
				<div>
					<div class="avater grey lighten-2">
						<ImageTemplate :src="userDetail.profile_img"></ImageTemplate>
					</div>

					<v-hover v-slot="{ hover }">
						<div
							class="mt-1 text-center fw-600 primary--text cursor-pointer"
							:class="{ 'text-decoration-underline': hover }"
							@click="selectFile"
						>
							CHANGE
						</div>
					</v-hover>

					<!-- <v-btn tile depressed color="blue darken-4 white--text" class="mt-2" v-on:click="selectFile()"
						>Change</v-btn
					> -->
				</div>

				<v-flex class="inforamtion pl-4 w-100">
					<div class="name d-flex">
						<div class="d-flex">
							<h4 class="fw-600 mb-0">{{ userDetail.display_name }}</h4>
							<v-chip
								v-if="userDetail.activated == 1"
								color="green"
								class="white--text ml-2"
								outlined
								style="letter-spacing: 0.8px"
								small
							>
								Activate
							</v-chip>
							<v-chip
								v-else
								color="red"
								class="white--text ml-2"
								outlined
								style="letter-spacing: 0.8px"
								small
							>
								Deactivate
							</v-chip>
						</div>

						<v-spacer></v-spacer>
						<v-btn
							v-if="getPermission('user:update')"
							color="blue darken-4 text-white"
							class="mr-2"
							depressed
							tile
							v-on:click="editUser({ id: usersId, action: 'Update' })"
							><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
						>
					</div>
					<div class="designation primary--text fw-500">{{ userDetail?.role?.role }}</div>
					<div class="email mt-1 fw-500">
						<v-icon class="icon-18 primary--text">mdi-email-outline</v-icon>
						{{ userDetail.email }}
					</div>
					<div class="email mt-1 fw-500" v-if="getProvision(userDetail.provision_date) >= 0">
						<v-chip
							v-if="getProvision(userDetail.provision_date) >= 0"
							color="red"
							class="white--text ml-2"
							outlined
							style="letter-spacing: 0.8px"
							small
						>
							Probation
						</v-chip>
					</div>
				</v-flex>
			</div>
			<div class="d-flex pt-0">
				<template>
					<v-tabs
						v-model="dataTab"
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent border-bottom w-100"
						active-class="blue darken-4 text-white"
						hide-slider
						@change="tabChange"
					>
						<v-tab href="#overall"><v-icon small left>mdi-information-outline</v-icon>Overall</v-tab>
						<v-tab href="#permission"><v-icon small left>mdi-account-key</v-icon>Permission</v-tab>
						<v-tab href="#password"> <v-icon small left>mdi-lock-reset</v-icon>Change Password</v-tab>
						<v-tab href="#leave"><v-icon small left>mdi-human-handsup</v-icon>Leave</v-tab>
						<v-tab href="#claim" v-if="AllowAnyOforGetRoleNType()?.users_type != 'seo'">
							<v-icon small left>mdi-note-text</v-icon>Claim
						</v-tab>
						<v-tab href="#activity"><v-icon small left>mdi-run</v-icon>Activity</v-tab>
					</v-tabs>
				</template>
				<v-spacer></v-spacer>
				<v-chip
					color="blue darken-4"
					class="white--text mr-2"
					label
					style="letter-spacing: 0.8px"
					v-if="
						userDetail.activated == 2 &&
						AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
					"
					@click="statusChange(1)"
				>
					Activate
				</v-chip>
				<v-chip
					color="red"
					class="white--text mr-2"
					label
					style="letter-spacing: 0.8px"
					v-if="
						userDetail.activated == 1 &&
						AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
					"
					@click="statusChange(2)"
				>
					Deactivate
				</v-chip>
			</div>

			<v-tabs-items v-model="dataTab" class="transparent">
				<v-tab-item value="overall">
					<div class="user-information px-4 pt-2">
						<div class="fw-600 py-2">User Information</div>

						<div class="d-flex mb-2">
							<div class="ml-2" style="width: 110px">First Name</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.first_name">
								{{ userDetail.first_name }}
							</div>
							<em class="ml-8" v-else>no first name</em>
						</div>
						<div class="d-flex mb-2">
							<div class="ml-2" style="width: 110px">Last Name</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.last_name">
								{{ userDetail.last_name }}
							</div>
							<em class="ml-8" v-else>no last name</em>
						</div>
						<div class="d-flex mb-2">
							<div class="ml-2" style="width: 110px">Email</div>
							<div class="ml-8 fw-500 primary--text" v-if="userDetail && userDetail.email">
								{{ userDetail.email }}
							</div>
							<em class="ml-8" v-else>no email</em>
						</div>
						<div class="d-flex mb-2">
							<div class="ml-2" style="width: 110px">Phone</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.phone_number">
								{{ userDetail.phone_number }}
							</div>
							<em class="ml-8" v-else>no phone</em>
						</div>
						<div class="d-flex mb-2">
							<div class="ml-2" style="width: 110px">Joining Date</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.joining_date">
								{{ formatDate(userDetail.joining_date) }}
								({{ userDetail.joining_date_formatted }})
							</div>
							<em class="ml-8" v-else>no joining date</em>
						</div>
						<div class="d-flex mb-2">
							<div class="ml-2" style="width: 110px">Probation Period</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.provision_date">
								{{ formatDate(userDetail.joining_date) }} <b>To</b>
								{{ formatDate(userDetail.provision_date) }}
							</div>
							<em class="ml-8" v-else>no probation period</em>
						</div>
						<div class="d-flex mb-2">
							<div class="ml-2" style="width: 110px">Role</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.role">
								{{ userDetail?.role?.role }}
							</div>
							<em class="ml-8" v-else>no role</em>
						</div>
						<div class="d-flex mb-2">
							<div class="ml-2" style="width: 110px">Designation</div>

							<div class="ml-8 fw-500 text-capitalize" v-if="userDetail.profile">
								<template v-if="userDetail.profile == 'email-marketer'">Email Marketer</template>
								<template v-else>{{ userDetail.profile }}</template>
							</div>
							<em class="ml-8 text-muted" v-else>no designation</em>
						</div>
						<!-- <div class="d-flex mb-2">
							<div class="txt-label">Added By</div>
							<div class="value fw-500" v-if="userDetail">{{ userDetail.display_name }}</div>
						</div> -->
					</div>
				</v-tab-item>
				<v-tab-item value="permission">
					<UserPemission
						v-if="dataTab == 'permission' && userType"
						:user-type="userType"
						:uuid="usersId"
					></UserPemission>
				</v-tab-item>
				<v-tab-item value="password">
					<div class="mx-4">
						<div class="overflow-y" style="max-height: calc(100vh - 250px)">
							<v-layout class="p-4 border-bottom-light-grey min-height-57px">
								<v-flex class="font-level-3-bold my-auto">
									<span class="detail-svg-icon mr-2">
										<!--begin::Svg Icon-->
										<inline-svg :src="$assetURL('media/custom-svg/member-color.svg')" />
										<!--end::Svg Icon-->
									</span>
									Change Password
								</v-flex>
								<v-flex class="font-level-3-bold my-auto float-right">
									<v-btn
										:loading="pageLoading"
										class="white--text mr-2 float-right"
										depressed
										color="blue darken-4"
										tile
										:disabled="pageLoading"
										@click="updatePassword"
									>
										Update Password
									</v-btn>
								</v-flex>
							</v-layout>
						</div>
					</div>
					<v-form
						ref="passwordForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updatePassword"
					>
						<v-row>
							<v-col md="6" class="py-0 pt-4" offset-md="3">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:type="show1 ? 'text' : 'password'"
									:loading="pageLoading"
									:append-outer-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append-outer="show1 = !show1"
									id="password"
									placeholder="Password"
									:rules="[
										vrules.required(password, 'New Password'),
										vrules.minLength(password, 'New Password', 8),
										vrules.maxLength(password, 'New Password', 16),
									]"
									:class="{
										required: !password,
									}"
									@keypress="avoidSpaces"
									v-model.trim="password"
								></TextInput>
							</v-col>
							<v-col md="6" class="py-0" offset-md="3">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:type="show3 ? 'text' : 'password'"
									:loading="pageLoading"
									:append-outer-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
									v-on:click:append-outer="show3 = !show3"
									id="confirm-password"
									placeholder="Confirm Password"
									:rules="[
										vrules.required(password_confirmation, 'Confirm Password'),
										vrules.confirmPassword(password_confirmation, 'Confirm Password', password),
										vrules.minLength(password_confirmation, 'Confirm Password', 8),
										vrules.maxLength(password_confirmation, 'Confirm Password', 16),
									]"
									:class="{
										required: !password_confirmation,
									}"
									v-model.trim="password_confirmation"
									@keypress="avoidSpaces"
								></TextInput>
							</v-col>
						</v-row>
					</v-form>
				</v-tab-item>
				<v-tab-item value="leave">
					<LeaveUpdate :user="usersId" v-on:leave:updated="dataTab = 'leave'"></LeaveUpdate>
				</v-tab-item>
				<v-tab-item value="claim" v-if="AllowAnyOforGetRoleNType()?.users_type != 'seo'">
					<ClaimUpdate :user="usersId" v-on:leave:updated="dataTab = 'claim'"></ClaimUpdate>
				</v-tab-item>
				<v-tab-item class="user-activity-tab" value="activity">
					<div>
						<div v-for="(logs, index) in activitylogData" :key="index">
							<ActivityTab :open_details="true" :log="logs" title="User"></ActivityTab>
						</div>
					</div>
				</v-tab-item>
			</v-tabs-items>
			<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
		</v-flex>
		<AddUser
			:drawer="add_new_user"
			v-on:refreshUserList="getUserLists"
			v-on:refreshUser="getSingleUser"
			:user-data="userDetail"
			title-status="Update"
			:action-status="actionStatus"
			v-on:close="add_new_user = false"
		></AddUser>
	</v-layout>
</template>

<script>
import AddUser from "@/view/module/users/AddUser";
import { GET, PATCH, QUERY, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LeaveUpdate from "@/view/module/users/LeaveUpdate";
import UserPemission from "@/view/module/users/UserPermission";
import ClaimUpdate from "@/view/module/users/ClaimUpdate";
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import ImageTemplate from "@/view/components/Image";
import { mapGetters } from "vuex";
import MomentJS from "moment-timezone";
import objectPath from "object-path";

export default {
	name: "user-detail",
	components: {
		AddUser,
		LeaveUpdate,
		UserPemission,
		TextInput,
		ActivityTab,
		ClaimUpdate,
		ImageTemplate,
	},
	data() {
		return {
			actionStatus: "Create",
			selectedItem: 0,
			add_new_user: false,
			current_date: new Date().toISOString().substr(0, 10),
			password_confirmation: null,
			password: null,
			dataTab: null,
			profile_img: "",
			formValid: true,
			pageLoading: false,
			show1: false,
			activitylogData: [],
			/* 	probisionTime:0, */
			show3: false,
			userStatus: [],
			headers: [
				{
					text: "Image",
					value: "avatar",
					align: "start",
					sortable: false,
				},
				{
					text: "Name",
					align: "start",
					sortable: false,
					value: "name",
				},
				{
					text: "Email",
					value: "email",
					sortable: false,
				},
				{
					text: "Joining",
					value: "joining",
					sortable: false,
				},
				{
					text: "Role",
					value: "role",
					sortable: false,
				},
			],
			userLists: [],
			userDetail: {},
			userType: null,
		};
	},
	watch: {
		getProfile: {
			deep: true,
			immediate: true,
			handler(profile) {
				let userType = objectPath.get(profile, "users_type");
				if (userType) {
					this.userType = userType;
				}
			},
		},
	},
	methods: {
		uploadFile(e) {
			let formData = new FormData();
			formData.append("files", e);
			formData.append("id", this.userDetail.id ? this.userDetail.id : 0);
			this.$store
				.dispatch(POST, { url: `image-change`, data: formData })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Image updated successfully.",
						},
					]);
					this.getSingleUser();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		tabChange(event) {
			const { params, query } = this.$route;
			this.$router.push({
				name: "user-profile",
				params: { id: params.id },
				query: { ...query, tab: event },
			});
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		userActivityDetails() {
			this.$store
				.dispatch(GET, { url: `user-activity` })
				.then((data) => {
					this.activitylogData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getProvision(pro) {
			let a = MomentJS();
			let b = MomentJS(pro);
			return b.diff(a, "days");
		},
		updatePassword() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.passwordForm);
			if (formErrors.length) {
				_this.errors = [];
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.passwordForm.validate()) {
				return false;
			}

			this.$store
				.dispatch(PATCH, {
					url: `user/${this.usersId}/password`,
					data: {
						password: this.password,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Password updated successfully.",
						},
					]);
					this.password = null;
					this.password_confirmation = null;
					this.getSingleUser();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getLeaveDays() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "setting/leave",
				})
				.then(({ data }) => {
					_this.leaveList = data;
				})
				.catch((error) => {
					_this.logError(error);
					_this.goBack();
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},

		getUserLists() {
			this.$store
				.dispatch(GET, {
					url: "all-users",
				})
				.then((data) => {
					this.userLists = data.tbody;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getSingleUser() {
			this.$store
				.dispatch(GET, {
					url: `single-user/${this.usersId}`,
				})
				.then((data) => {
					this.userDetail = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		tabDetails(id) {
			this.$router.push({
				name: "user-detail",
				params: {
					id,
				},
			});
			this.usersId = id;
			this.getSingleUser();
			/* this.getLeaveDays(); */
		},
		statusChange(status) {
			this.$store
				.dispatch(PATCH, {
					url: `user/${this.usersId}`,
					data: {
						status: status,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Status updated successfully.",
						},
					]);
					this.getSingleUser();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		userSetting() {
			this.$store
				.dispatch(GET, {
					url: `user-setting`,
				})
				.then((data) => {
					this.userStatus = data.status;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		statusFilterData(value) {
			this.$store
				.dispatch(QUERY, {
					url: `all-users`,
					data: {
						status: value,
					},
				})
				.then((data) => {
					this.userLists = data.rows;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		editUser(data) {
			const id = data.id;
			this.actionStatus = data.action;
			this.add_new_user = true;
			this.$store
				.dispatch(GET, { url: `single-user/${id}` })
				.then((data) => {
					this.userDetail = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		createUser() {
			this.add_new_user = true;
			this.actionStatus = "Create";
		},
	},
	mounted() {
		this.getUserLists();
		this.getProvision(this.userDetail.provision_date);
		this.getSingleUser();
		this.userSetting();
		this.getLeaveDays();
		this.userActivityDetails();
		this.$store.dispatch(SET_BREADCRUMB, [
			// { text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Users", disabled: true },
			{ text: "Profile", disabled: true },
		]);
	},
	beforeMount() {
		this.usersId = toSafeInteger(this.$route.params.id);
		this.dataTab = this.$route.query.tab || "overall";
	},
	computed: {
		...mapGetters(["getProfile", "currentUser"]),

		getFullName() {
			return "";
		},
	},
};
</script>

<style scoped></style>
