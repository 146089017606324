var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticStyle:{"z-index":"99"},attrs:{"fixed":"","width":"600","right":"","temporary":"","stateless":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"drawer-wrapper"},[_c('v-flex',{staticClass:"py-3 px-5 d-flex border-bottom drawer-header white--text orange darken-2"},[_c('div',{staticClass:"font-level-3-bold my-auto"},[(_vm.userData.id)?[_vm._v("Update")]:[_vm._v("Create")],_vm._v(" User ")],2),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"tile":"","color":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"white--text ml-2",attrs:{"tile":"","color":"blue darken-4","loading":_vm.pageLoading,"disabled":_vm.pageLoading || !_vm.formValid},on:{"click":_vm.update_or_create}},[_vm._v("Save")])],1),_c('v-form',{ref:"userForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.update_or_create.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('div',{staticClass:"drawer-content pt-5 px-5"},[(!_vm.userData.id)?_c('div',{staticClass:"d-form-grp mt-4",staticStyle:{"position":"relative"}},[_c('ImageUpload',{attrs:{"can-change":"","page-loading":_vm.pageLoading},model:{value:(_vm.users.profile_img),callback:function ($$v) {_vm.$set(_vm.users, "profile_img", $$v)},expression:"users.profile_img"}})],1):_vm._e(),_c('div',{staticStyle:{"height":"calc(100vh - 80px)","overflow-y":"auto"}},[_c('div',{staticClass:"d-form-grp mt-4"},[_c('label',{staticClass:"mb-2 required"},[_vm._v("Title ")]),_c('v-autocomplete',{class:{
									required: !_vm.users.title,
								},attrs:{"items":_vm.userTitle,"item-text":"text","item-value":"value","hide-details":"","outlined":"","placeholder":"Title","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.users.title, 'Title')]},model:{value:(_vm.users.title),callback:function ($$v) {_vm.$set(_vm.users, "title", $$v)},expression:"users.title"}})],1),_c('div',{staticClass:"d-form-grp mt-4"},[_c('label',{staticClass:"mb-2 required"},[_vm._v("First Name")]),_c('v-text-field',{class:{
									required: !_vm.users.first_name,
								},attrs:{"hide-details":"","outlined":"","placeholder":"First Name","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.users.first_name, 'First Name')]},model:{value:(_vm.users.first_name),callback:function ($$v) {_vm.$set(_vm.users, "first_name", $$v)},expression:"users.first_name"}})],1),_c('div',{staticClass:"d-form-grp mt-4"},[_c('label',{staticClass:"mb-2"},[_vm._v("Last Name")]),_c('v-text-field',{attrs:{"hide-details":"","outlined":"","placeholder":"Last Name","disabled":_vm.pageLoading,"loading":_vm.pageLoading},model:{value:(_vm.users.last_name),callback:function ($$v) {_vm.$set(_vm.users, "last_name", $$v)},expression:"users.last_name"}})],1),(_vm.getValue(_vm.getProfile, 'is_master') == 1)?_c('div',{staticClass:"d-form-grp mt-4 mb-3"},[_c('label',{staticClass:"mb-2"},[_vm._v("User Type")]),_c('v-autocomplete',{attrs:{"items":_vm.users_type_list,"item-text":"title","item-value":"value","hide-details":"","outlined":"","placeholder":"User Type"},on:{"change":_vm.filterUserRoles},model:{value:(_vm.users.users_type),callback:function ($$v) {_vm.$set(_vm.users, "users_type", $$v)},expression:"users.users_type"}})],1):_vm._e(),_c('div',{staticClass:"d-form-grp mt-4"},[_c('label',{staticClass:"mb-2 required"},[_vm._v("Email")]),_c('v-text-field',{class:{
									required: !_vm.users.email,
								},attrs:{"hide-details":"","outlined":"","placeholder":"Email","append-icon":"mdi-email","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.users.email, 'Email'), _vm.vrules.validEmail(_vm.users.email, 'Email')]},model:{value:(_vm.users.email),callback:function ($$v) {_vm.$set(_vm.users, "email", $$v)},expression:"users.email"}})],1),_c('div',{staticClass:"d-form-grp mt-4"},[_c('label',{staticClass:"required"},[_vm._v("Joining Date")]),_c('DatePicker',{class:{
									required: !_vm.users.joining_date,
								},attrs:{"hide-details":"","clearable":"","disabled":_vm.pageLoading,"readonly":_vm.userData.id &&
									!_vm.AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead']),"loading":_vm.pageLoading,"id":"joining-date","placeholder":"Joining Date","rules":[_vm.vrules.required(_vm.users.joining_date, 'Joining Date')]},on:{"change":_vm.provisionDate},model:{value:(_vm.users.joining_date),callback:function ($$v) {_vm.$set(_vm.users, "joining_date", $$v)},expression:"users.joining_date"}})],1),_c('div',{staticClass:"d-form-grp mt-4"},[_c('label',{staticClass:"required"},[_vm._v("Probation Date")]),_c('DatePicker',{class:{
									required: !_vm.users.provision_date,
								},attrs:{"hide-details":"","clearable":"","readonly":_vm.userData.id &&
									!_vm.AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead']),"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"provision-date","placeholder":"Probation Date","rules":[_vm.vrules.required(_vm.users.provision_date, 'Probation Date')]},model:{value:(_vm.users.provision_date),callback:function ($$v) {_vm.$set(_vm.users, "provision_date", $$v)},expression:"users.provision_date"}})],1),_c('div',{staticClass:"d-form-grp mt-4"},[_c('label',{staticClass:"mb-2"},[_vm._v("Phone Number")]),_c('PhoneTemplate',{ref:"userPhoneNumber",attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"phone_number"},on:{"clear":function($event){_vm.users.phone_number = null},"loading":($event) => (_vm.pageLoading = $event),"validity":function($event){_vm.phoneValidity = $event}},model:{value:(_vm.users.phone_number),callback:function ($$v) {_vm.$set(_vm.users, "phone_number", $$v)},expression:"users.phone_number"}})],1),_c('div',{staticClass:"d-form-grp mt-4"},[_c('label',{staticClass:"mb-2 required"},[_vm._v("Role")]),_c('v-autocomplete',{class:{
									required: !_vm.users.role,
								},attrs:{"items":_vm.userRoles,"item-text":"role","item-value":"id","hide-details":"","outlined":"","placeholder":"Role","readonly":_vm.userData.id &&
									!_vm.AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead']),"prepend-inner-icon":"mdi-account","rules":[_vm.vrules.required(_vm.users.role, 'Role')]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"fw-500 py-1 text-capitalize"},[_vm._v(" "+_vm._s(item.role)+" ")])]}}]),model:{value:(_vm.users.role),callback:function ($$v) {_vm.$set(_vm.users, "role", $$v)},expression:"users.role"}})],1),_c('div',{staticClass:"d-form-grp mt-4 mb-3"},[_c('label',{staticClass:"mb-2"},[_vm._v("Designation")]),_c('v-autocomplete',{attrs:{"items":_vm.userProfile,"item-text":"text","item-value":"value","hide-details":"","outlined":"","readonly":!_vm.AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead']),"placeholder":"Designation"},model:{value:(_vm.users.profile),callback:function ($$v) {_vm.$set(_vm.users, "profile", $$v)},expression:"users.profile"}})],1)])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }