var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{staticClass:"p-4 border-bottom-light-grey min-height-57px"},[_c('v-flex',{staticClass:"font-level-3-bold my-auto"},[_c('span',{staticClass:"detail-svg-icon mr-2"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/reservation-color.svg')}})],1),_vm._v(" Permissions "),(
					!_vm.editFlag &&
					_vm.AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
				)?_c('v-icon',{staticClass:"primary--text px-2",attrs:{"size":"19"},on:{"click":function($event){_vm.editFlag = true;
					_vm.readonly = false;}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.editFlag)?_c('v-btn',{staticClass:"float-right",attrs:{"disabled":_vm.formLoading,"depressed":"","tile":""},on:{"click":function($event){_vm.editFlag = false;
					_vm.readonly = true;}}},[_vm._v(" Cancel ")]):_vm._e(),(
					_vm.editFlag &&
					_vm.AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
				)?_c('v-btn',{staticClass:"float-right mx-2 custom-bold-button white--text",attrs:{"disabled":_vm.formLoading,"loading":_vm.formLoading,"tile":"","depressed":"","color":"blue darken-4 white--text"},on:{"click":_vm.updateUserPermission}},[_vm._v(" Update Permissions ")]):_vm._e()],1)],1),(_vm.formLoading)?_c('div',{staticClass:"mt-12 px-12"},[_c('h2',[_vm._v("Loading...")]),_c('v-progress-linear',{attrs:{"height":"12","color":"orange","indeterminate":""}})],1):_c('div',{staticStyle:{"max-height":"calc(100vh - 80px)","overflow-y":"auto"}},[_c('Permissions',{attrs:{"permissions":_vm.permissions,"updatePermissionsList":_vm.formPermissionsList,"readonly":_vm.readonly},on:{"updatePermissionData":_vm.updatePermissionData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }